/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AirconCondition } from './AirconCondition';
import {
    AirconConditionFromJSON,
    AirconConditionFromJSONTyped,
    AirconConditionToJSON,
} from './AirconCondition';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { AuctionStatus } from './AuctionStatus';
import {
    AuctionStatusFromJSON,
    AuctionStatusFromJSONTyped,
    AuctionStatusToJSON,
} from './AuctionStatus';
import type { ExtraInfo } from './ExtraInfo';
import {
    ExtraInfoFromJSON,
    ExtraInfoFromJSONTyped,
    ExtraInfoToJSON,
} from './ExtraInfo';
import type { KeyAmount } from './KeyAmount';
import {
    KeyAmountFromJSON,
    KeyAmountFromJSONTyped,
    KeyAmountToJSON,
} from './KeyAmount';
import type { PickupStatus } from './PickupStatus';
import {
    PickupStatusFromJSON,
    PickupStatusFromJSONTyped,
    PickupStatusToJSON,
} from './PickupStatus';
import type { ServiceBook } from './ServiceBook';
import {
    ServiceBookFromJSON,
    ServiceBookFromJSONTyped,
    ServiceBookToJSON,
} from './ServiceBook';
import type { Specification } from './Specification';
import {
    SpecificationFromJSON,
    SpecificationFromJSONTyped,
    SpecificationToJSON,
} from './Specification';
import type { TimingBeltChanged } from './TimingBeltChanged';
import {
    TimingBeltChangedFromJSON,
    TimingBeltChangedFromJSONTyped,
    TimingBeltChangedToJSON,
} from './TimingBeltChanged';
import type { TireCondition } from './TireCondition';
import {
    TireConditionFromJSON,
    TireConditionFromJSONTyped,
    TireConditionToJSON,
} from './TireCondition';
import type { UserBid } from './UserBid';
import {
    UserBidFromJSON,
    UserBidFromJSONTyped,
    UserBidToJSON,
} from './UserBid';
import type { UserMessage } from './UserMessage';
import {
    UserMessageFromJSON,
    UserMessageFromJSONTyped,
    UserMessageToJSON,
} from './UserMessage';
import type { WindshieldCondition } from './WindshieldCondition';
import {
    WindshieldConditionFromJSON,
    WindshieldConditionFromJSONTyped,
    WindshieldConditionToJSON,
} from './WindshieldCondition';

/**
 * 
 * @export
 * @interface AuctionDetailData
 */
export interface AuctionDetailData {
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    auctionId?: string;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    auctionUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    registrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    variant?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionDetailData
     */
    firstRegistrationAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    mileage?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    propellant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    transmission?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    driveTrain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    city?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    zipCode?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    priceExpectation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionDetailData
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuctionDetailData
     */
    expireAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionDetailData
     */
    inactiveAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    bidCountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    year?: number;
    /**
     * 
     * @type {AuctionStatus}
     * @memberof AuctionDetailData
     */
    auctionStatus?: AuctionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    hasWinner?: boolean;
    /**
     * 
     * @type {Attachment}
     * @memberof AuctionDetailData
     */
    attachment?: Attachment;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    vat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    regTax?: boolean | null;
    /**
     * 
     * @type {UserBid}
     * @memberof AuctionDetailData
     */
    currentUserBid?: UserBid;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    isFavorite?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    extraComments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    errorComments?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    lampsBlinking?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionDetailData
     */
    lampsBlinkingText?: string | null;
    /**
     * 
     * @type {TireCondition}
     * @memberof AuctionDetailData
     */
    summerTireCondition?: TireCondition;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    summerRims?: boolean | null;
    /**
     * 
     * @type {TireCondition}
     * @memberof AuctionDetailData
     */
    winterTireCondition?: TireCondition;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    winterRims?: boolean | null;
    /**
     * 
     * @type {TireCondition}
     * @memberof AuctionDetailData
     */
    allSeasonTireCondition?: TireCondition;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDetailData
     */
    allSeasonTireRims?: boolean | null;
    /**
     * 
     * @type {WindshieldCondition}
     * @memberof AuctionDetailData
     */
    windshieldCondition?: WindshieldCondition;
    /**
     * 
     * @type {KeyAmount}
     * @memberof AuctionDetailData
     */
    numberOfKeys?: KeyAmount;
    /**
     * 
     * @type {ServiceBook}
     * @memberof AuctionDetailData
     */
    services?: ServiceBook;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    serviceMileage?: number | null;
    /**
     * 
     * @type {UserMessage}
     * @memberof AuctionDetailData
     */
    currentMessage?: UserMessage;
    /**
     * 
     * @type {PickupStatus}
     * @memberof AuctionDetailData
     */
    pickupStatus?: PickupStatus;
    /**
     * 
     * @type {AirconCondition}
     * @memberof AuctionDetailData
     */
    airconCondition?: AirconCondition;
    /**
     * 
     * @type {TimingBeltChanged}
     * @memberof AuctionDetailData
     */
    timingBeltChanged?: TimingBeltChanged;
    /**
     * 
     * @type {number}
     * @memberof AuctionDetailData
     */
    mileageTimingBeltChanged?: number | null;
    /**
     * 
     * @type {Array<Specification>}
     * @memberof AuctionDetailData
     */
    specifications?: Array<Specification> | null;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof AuctionDetailData
     */
    attachments?: Array<Attachment> | null;
    /**
     * 
     * @type {Array<ExtraInfo>}
     * @memberof AuctionDetailData
     */
    extraInfos?: Array<ExtraInfo> | null;
}

/**
 * Check if a given object implements the AuctionDetailData interface.
 */
export function instanceOfAuctionDetailData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuctionDetailDataFromJSON(json: any): AuctionDetailData {
    return AuctionDetailDataFromJSONTyped(json, false);
}

export function AuctionDetailDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionDetailData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auctionId': !exists(json, 'auctionId') ? undefined : json['auctionId'],
        'auctionUserId': !exists(json, 'auctionUserId') ? undefined : json['auctionUserId'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'firstRegistrationAt': !exists(json, 'firstRegistrationAt') ? undefined : (json['firstRegistrationAt'] === null ? null : new Date(json['firstRegistrationAt'])),
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'propellant': !exists(json, 'propellant') ? undefined : json['propellant'],
        'transmission': !exists(json, 'transmission') ? undefined : json['transmission'],
        'driveTrain': !exists(json, 'driveTrain') ? undefined : json['driveTrain'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'priceExpectation': !exists(json, 'priceExpectation') ? undefined : json['priceExpectation'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'expireAt': !exists(json, 'expireAt') ? undefined : (json['expireAt'] === null ? null : new Date(json['expireAt'])),
        'inactiveAt': !exists(json, 'inactiveAt') ? undefined : (json['inactiveAt'] === null ? null : new Date(json['inactiveAt'])),
        'bidCountTotal': !exists(json, 'bidCountTotal') ? undefined : json['bidCountTotal'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'auctionStatus': !exists(json, 'auctionStatus') ? undefined : AuctionStatusFromJSON(json['auctionStatus']),
        'hasWinner': !exists(json, 'hasWinner') ? undefined : json['hasWinner'],
        'attachment': !exists(json, 'attachment') ? undefined : AttachmentFromJSON(json['attachment']),
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'regTax': !exists(json, 'regTax') ? undefined : json['regTax'],
        'currentUserBid': !exists(json, 'currentUserBid') ? undefined : UserBidFromJSON(json['currentUserBid']),
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
        'extraComments': !exists(json, 'extraComments') ? undefined : json['extraComments'],
        'errorComments': !exists(json, 'errorComments') ? undefined : json['errorComments'],
        'lampsBlinking': !exists(json, 'lampsBlinking') ? undefined : json['lampsBlinking'],
        'lampsBlinkingText': !exists(json, 'lampsBlinkingText') ? undefined : json['lampsBlinkingText'],
        'summerTireCondition': !exists(json, 'summerTireCondition') ? undefined : TireConditionFromJSON(json['summerTireCondition']),
        'summerRims': !exists(json, 'summerRims') ? undefined : json['summerRims'],
        'winterTireCondition': !exists(json, 'winterTireCondition') ? undefined : TireConditionFromJSON(json['winterTireCondition']),
        'winterRims': !exists(json, 'winterRims') ? undefined : json['winterRims'],
        'allSeasonTireCondition': !exists(json, 'allSeasonTireCondition') ? undefined : TireConditionFromJSON(json['allSeasonTireCondition']),
        'allSeasonTireRims': !exists(json, 'allSeasonTireRims') ? undefined : json['allSeasonTireRims'],
        'windshieldCondition': !exists(json, 'windshieldCondition') ? undefined : WindshieldConditionFromJSON(json['windshieldCondition']),
        'numberOfKeys': !exists(json, 'numberOfKeys') ? undefined : KeyAmountFromJSON(json['numberOfKeys']),
        'services': !exists(json, 'services') ? undefined : ServiceBookFromJSON(json['services']),
        'serviceMileage': !exists(json, 'serviceMileage') ? undefined : json['serviceMileage'],
        'currentMessage': !exists(json, 'currentMessage') ? undefined : UserMessageFromJSON(json['currentMessage']),
        'pickupStatus': !exists(json, 'pickupStatus') ? undefined : PickupStatusFromJSON(json['pickupStatus']),
        'airconCondition': !exists(json, 'airconCondition') ? undefined : AirconConditionFromJSON(json['airconCondition']),
        'timingBeltChanged': !exists(json, 'timingBeltChanged') ? undefined : TimingBeltChangedFromJSON(json['timingBeltChanged']),
        'mileageTimingBeltChanged': !exists(json, 'mileageTimingBeltChanged') ? undefined : json['mileageTimingBeltChanged'],
        'specifications': !exists(json, 'specifications') ? undefined : (json['specifications'] === null ? null : (json['specifications'] as Array<any>).map(SpecificationFromJSON)),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'extraInfos': !exists(json, 'extraInfos') ? undefined : (json['extraInfos'] === null ? null : (json['extraInfos'] as Array<any>).map(ExtraInfoFromJSON)),
    };
}

export function AuctionDetailDataToJSON(value?: AuctionDetailData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auctionId': value.auctionId,
        'auctionUserId': value.auctionUserId,
        'vin': value.vin,
        'registrationNumber': value.registrationNumber,
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'firstRegistrationAt': value.firstRegistrationAt === undefined ? undefined : (value.firstRegistrationAt === null ? null : value.firstRegistrationAt.toISOString()),
        'mileage': value.mileage,
        'propellant': value.propellant,
        'transmission': value.transmission,
        'driveTrain': value.driveTrain,
        'city': value.city,
        'zipCode': value.zipCode,
        'priceExpectation': value.priceExpectation,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'expireAt': value.expireAt === undefined ? undefined : (value.expireAt === null ? null : value.expireAt.toISOString()),
        'inactiveAt': value.inactiveAt === undefined ? undefined : (value.inactiveAt === null ? null : value.inactiveAt.toISOString()),
        'bidCountTotal': value.bidCountTotal,
        'year': value.year,
        'auctionStatus': AuctionStatusToJSON(value.auctionStatus),
        'hasWinner': value.hasWinner,
        'attachment': AttachmentToJSON(value.attachment),
        'vat': value.vat,
        'regTax': value.regTax,
        'currentUserBid': UserBidToJSON(value.currentUserBid),
        'isFavorite': value.isFavorite,
        'extraComments': value.extraComments,
        'errorComments': value.errorComments,
        'lampsBlinking': value.lampsBlinking,
        'lampsBlinkingText': value.lampsBlinkingText,
        'summerTireCondition': TireConditionToJSON(value.summerTireCondition),
        'summerRims': value.summerRims,
        'winterTireCondition': TireConditionToJSON(value.winterTireCondition),
        'winterRims': value.winterRims,
        'allSeasonTireCondition': TireConditionToJSON(value.allSeasonTireCondition),
        'allSeasonTireRims': value.allSeasonTireRims,
        'windshieldCondition': WindshieldConditionToJSON(value.windshieldCondition),
        'numberOfKeys': KeyAmountToJSON(value.numberOfKeys),
        'services': ServiceBookToJSON(value.services),
        'serviceMileage': value.serviceMileage,
        'currentMessage': UserMessageToJSON(value.currentMessage),
        'pickupStatus': PickupStatusToJSON(value.pickupStatus),
        'airconCondition': AirconConditionToJSON(value.airconCondition),
        'timingBeltChanged': TimingBeltChangedToJSON(value.timingBeltChanged),
        'mileageTimingBeltChanged': value.mileageTimingBeltChanged,
        'specifications': value.specifications === undefined ? undefined : (value.specifications === null ? null : (value.specifications as Array<any>).map(SpecificationToJSON)),
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(AttachmentToJSON)),
        'extraInfos': value.extraInfos === undefined ? undefined : (value.extraInfos === null ? null : (value.extraInfos as Array<any>).map(ExtraInfoToJSON)),
    };
}

