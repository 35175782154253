/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PickupStatus = {
    AFewDays: 'AFewDays',
    AWeek: 'AWeek',
    TwoWeeks: 'TwoWeeks',
    MoreThanTwoWeeks: 'MoreThanTwoWeeks'
} as const;
export type PickupStatus = typeof PickupStatus[keyof typeof PickupStatus];


export function PickupStatusFromJSON(json: any): PickupStatus {
    return PickupStatusFromJSONTyped(json, false);
}

export function PickupStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickupStatus {
    return json as PickupStatus;
}

export function PickupStatusToJSON(value?: PickupStatus | null): any {
    return value as any;
}

